
























































































import {Component, Vue} from "vue-property-decorator";
import UpdatePersonDetailsPayload from "@/dto/person/UpdatePersonDetailsRequestDTO";
import PhoneInput from "@/components/util/PhoneInput.vue";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import UserDTO from "@/dto/UserDTO";
import {namespace} from "vuex-class";
import RouteNames from "@/router/RouteNames";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PersonDTO from "@/dto/person/PersonDTO";
import PersonService from "@/services/PersonService";
import AddressDTO from "@/dto/AddressDTO";
import Workspaces from "@/state/Workspaces";
import PortalInput from "@/components/common/PortalInput.vue";
import {ifValid} from "@/utils/ComponentUtils";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import EmployeeService from "@/services/EmploymentService";
import EmploymentDTO from "@/dto/employee/EmploymentDTO";
import {IEmployment} from "@/dto/payroll/IEmployment";
import PortalDate from "@/components/common/PortalDate.vue";
import {PersonSex} from "@/constants/PersonConstants";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  computed: {
    PersonSex() {
      return PersonSex
    }
  },
	methods: {
		RouteNames() {
			return RouteNames
		}
	},
    components: {BTPortalCollapse, PortalInput, PhoneInput, PortalDate}
})
export default class PersonDetails extends Vue {

    payload = new UpdatePersonDetailsPayload();

	  private employments: Array<IEmployment> = [];

	  private detailsIsOpen = true;

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    private address = "";

    private email = "";

    private routes = RouteNames

    @AppModule.State
    private isMobile!: boolean;

    @AppModule.Action
    private startLoading!: () => void;

    @AppModule.Action
    private stopLoading!: () => void;

    @Auth.State
    private user!: UserDTO

    private isDataReady = false;

    private message = "";

    private successful = false;

    mounted() {
		this.startLoading();
        Promise.all([this.loadPersonInfo(), this.loadEmployments()]).then(
			res => {
				this.successful = true;
				this.message = "";
				this.stopLoading();
			},
			err => this.errorHandle(err)
		)
    }

	private loadEmployments(): Promise<void> {
		this.message = "";
		this.successful = false;
		return EmployeeService.getAllEmploymentsByDetailsId().then(
			res => {
				if (res.data) {
					this.employments.push(...res.data)
				}
			},
			err => {
				this.errorHandle(err)
			}
		)
	}

    private loadPersonInfo(): Promise<void> {
        this.message = "";
        this.successful = false;
        this.startLoading();
        return PersonService.getById(Workspaces.getCurrent.id).then(
            res => {
                this.isDataReady = true;
                this.mapPersonDTOToUpdatePersonRequest(res.data);
                this.successful = true;
                this.stopLoading();
            },
            err => {
                this.errorHandle(err)
            }
        )
    }

    private errorHandle(error: any) {
        this.successful = false;
        const param = error?.response?.data?.param ? { param: error?.response?.data?.param } : {}
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(error), param) as string;
        this.stopLoading();
    }

  private mapPersonDTOToUpdatePersonRequest(resPerson: PersonDTO) {
    this.payload.firstName = resPerson.firstName;
    this.payload.lastName = resPerson.lastName;
    this.payload.ssn = resPerson.ssn;
    this.payload.phone = resPerson.phone
    this.payload.birthDate = resPerson.birthDate
    this.payload.countryOfBirth = resPerson.countryOfBirth
    this.payload.nationality = resPerson.nationality
    this.payload.sex = resPerson.sex
    this.address = this.buildAddress(resPerson.address);
    this.payload.nationalTin = resPerson.nationalTin
    this.email = resPerson.email ? resPerson.email : "No email";
  }

    private buildAddress(address: AddressDTO | null) : string {
        if (!address) return "No address";
        return (address.city ? address.city.concat(" ") : " ")
            .concat(address.state as string).concat(" ")
            .concat(address.address1 ? address.address1 as string : address.foreignAddress as string).concat(" ")
            .concat(address.zipCode);
    }

    private successHandle() {
        this.loadPersonInfo();
        this.stopLoading();
        this.successful = true;
        this.message = this.$t("util.success.person_details") as string;
    }

  private save() {
    this.message = "";
    ifValid(this, () => {
      this.startLoading();
      this.payload.personId = Workspaces.getCurrent.id;
      PersonService.updatePersonDetails(this.payload).then(
          () => {
            this.successHandle()
          },
          (error) => {
            this.errorHandle(error)
          }
      ).then(() => this.stopLoading());
    })
  }

    private redirectToAccountSettings() {
        this.$router.push({name: this.routes.PROFILE_ACCOUNT, params: {type: 'p', id: String(Workspaces.getCurrent.id)}});
    }

    private redirectToAddressSettings() {
        this.$router.push({name: this.routes.PROFILE_ADDRESS, params: {type: 'p', id: String(Workspaces.getCurrent.id)}});
    }
}
