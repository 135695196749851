import {PersonSex} from "@/constants/PersonConstants";

export default class UpdatePersonDetailsRequestDTO {
    public personId: number | null = null;

    public firstName: string | null = null;

    public lastName: string | null = null;

    public ssn: string | null = null;

    public nationalTin: string | null = null;

    public phone: string | null = null;

    public birthDate: Date | null = null;

    public countryOfBirth: string | null = null;

    public nationality: string | null = null;

    public sex: PersonSex | null = null;

}